import React, {FC} from 'react';
import {Container} from 'reactstrap';
import {NavMenu} from "sacatelle-common";
import styles from "./Layout.module.scss"

export const Layout: FC = (props: any) => {
  return (
    <div style={{ minWidth: 380, overflow: 'hidden' }}>
      <NavMenu
          numOfRightItems={0}
        role={"administrator"}
        listOfRightLinks={links.right}
        listOfCenterLinks={links.center}

      />
      <Container fluid className={styles.container}>
        {props.children}
      </Container>
    </div>
  );
}
const links = {
    center: [

    ],
    right: [

    ],
};
