
export const getToken = (role?: string) =>{ 
    if (role == undefined)
        role = "client";
    return localStorage.getItem(role);
  }
  export const deleteToken = (role?:  string) => {
    if (role == undefined)
        role = "client";
    localStorage.setItem(role, "");
  }
  export const setToken = (token: string, role?:  string) => {
    if (role == undefined)
        role = "client";
    localStorage.setItem(role, token);
  }