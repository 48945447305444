import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  UriFunction,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import React, { FC, useMemo } from "react";
import { getToken } from "../controls";
import { ErrorRedirect } from "./ErrorRedirect";
interface Props {
  uri: string | UriFunction | undefined;
  showError: any;
  role?: string;
}
export const ErrorHandlingApolloProvider: FC<{
  uri: string | UriFunction | undefined;
  role?: string;
}> = (props) => {
  return (
    <ErrorRedirect role={props.role}>
      {(showError) => (
        <MyApolloProvider showError={showError} role={props.role} uri={props.uri}>
          {props.children}
        </MyApolloProvider>
      )}
    </ErrorRedirect>
  );
};

const MyApolloProvider: FC<Props> = (props) => {
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = getToken(props.role);
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const apolloClient = useMemo(
    () =>
      new ApolloClient({
        cache: new InMemoryCache({
          addTypename: false,
        }),
        link: authLink.concat(
          ApolloLink.from([
            onError(({ graphQLErrors, networkError }) => {
              if (networkError) {
                props.showError(networkError);
              }

              if (graphQLErrors) {
                props.showError(graphQLErrors);
              }
            }),
            new HttpLink({ uri: props.uri }),
          ])
        ),
      }),
    [props.showError]
  );
  return (
    <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>
  );
};