import { Component } from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import {
    OrderDetailsContainer,
    DesignTask,
} from "sacatelle-orders";
import {CartContext} from "sacatelle-cart";
import {
    loginRoute,
    logoutRoute,
    LogoutContainer,
    registerRoute,
    RegisterContainer,
    VerificationRequiredContainer,
    createNewPasswordRoute,
    verificationRoute,
    ResetPassword,
    LoginContainer,
    ClientAccount,
} from "sacatelle-login-client"
import {Products} from "sacatelle-products";
import "./App.scss";
import React from "react";
import { Layout } from "./Layout";
import {Orders} from "sacatelle-orders"

class AdminApp extends Component<{}, {}> {
    constructor(props) {
        super(props);
    }
    render() {
        var role = "administrator";
        return (
            <Layout>
                <Routes>
                    <Route 
                        path={loginRoute}
                        element={<LoginContainer
                        onSuccessRedirect="/products"
                        role={role}/>}
            
                    />
                    <Route path={logoutRoute} 
                    element={
                        <LogoutContainer role={role} loginRoute={loginRoute} />} 
                />
                <Route path={registerRoute}
                    element={
                        <RegisterContainer
                            role={role}
                        />}
                />
                <Route path={createNewPasswordRoute}
                    element={
                        <ResetPassword
                            role={role}
                            title={"Create New Password"}
                        />}
                />
                <Route path={verificationRoute}
                    element={<VerificationRequiredContainer />}
                />
                <Route
                    path="/" 
                    element={<Navigate to={loginRoute}/>} 
                />
                <Route 
                    
                    path="/products" 
                    element={<Products
                        cartContext={CartContext}
                        blobStorageUrl={process.env.REACT_APP_BLOB + "products/"}
                        role={role} 
                    />
                } 
                />
                <Route path='/requests'  element={<Orders isActive={true} role={role}  blobStorageUrl={process.env.REACT_APP_BLOB} />}/>
                <Route path='/orders'  element={<Orders role={role} blobStorageUrl={process.env.REACT_APP_BLOB} />}/>
                <Route path="/orders/order/:orderNumber" element={<OrderDetailsContainer  role={role} graphQlUrl={process.env.REACT_APP_GRAPHQL}  />}/>
                <Route path='/requests/request/:requestNumber' element={<DesignTask role={role} blobStorageUrl={process.env.REACT_APP_BLOB}/>}/>
                <Route path='/account' element={<ClientAccount/>}/>
                </Routes>
            </Layout>
        )
    }
}


export default AdminApp;
