import ReactDOM from "react-dom";
import "./index.css";
// import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import React from 'react';
import AdminApp from "./App";
import { ErrorHandlingApolloProvider } from "sacatelle-apollo-client";
const localGraphQL = process.env.REACT_APP_GRAPHQL;
// loads the Icon plugin
ReactDOM.render(
  <Router>
    <ErrorHandlingApolloProvider role={"administrator"} uri={localGraphQL}>
      <AdminApp />
    </ErrorHandlingApolloProvider>
  </Router>,
  document.getElementById("root")
);
