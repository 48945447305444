import React,{ useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { deleteToken } from "../controls";


export function ErrorRedirect({ role, children }) {
    var redirectToLogin = () => {
        window.location.href =  "/login";
    }
    return (
        <div>  
            {children((errors, e) => {
                if (errors && errors.length > 0) {
                if (errors[0].message == "Invalid token" || errors[0].message == "Context creation failed: Cant find user"){
                    toast.error(errors[0].message)
                    deleteToken(role);
                    redirectToLogin();
                }
                    //insert more error options
                else if (errors[0].message == "Expired token"){
                    toast.warn(errors[0].message);
                    deleteToken(role);
                    redirectToLogin();
                }
                else if (errors[0].message == "Cant find user"){
                    toast.warn("User not found")
                }
                else if (errors[0].message == "Not found"){
                    toast.warn(errors[0].message)
                }
                else if (errors[0].message == "User already exists"){
                    toast.warn(errors[0].message)
                }
                else if (errors[0].message == "Invalid pin"){
                    toast.warn(errors[0].message)
                }
                else if (errors[0].message == "Not authorized"){
                    toast.error("You are not authorized to perform this operation")
                }
                else if (errors[0].message == "Invalid password"){
                    toast.warn(errors[0].message)
                
                    
                }
                else
                    toast.error(errors[0].message);
            }
            
            else
            toast.warn("Your request could not be completed")
        })}
        <ToastContainer
          position="top-center"
          autoClose={3000}
          style={{ zIndex: 999999 }} 
          />
        </div>
    );
}
